<template>
  <v-container>
    <v-row class="row search spacer-md">
      <!-- Benutzer -->
      <v-col cols="12" sm="6" md="4" lg="2">
        <search-application-user ref="search-application-user" @search="search" />
      </v-col>
      <!--      Kontakt-->
      <v-col cols="12" sm="6" md="4" lg="2">
        <search-contact ref="search-contact" @search="search" />
      </v-col>
      <!--      Rechtliche Person-->
      <v-col cols="12" sm="6" md="4" lg="2">
        <search-legal-entity ref="search-legal-entity" @search="search" />
      </v-col>
    </v-row>

    <v-btn color="primary" @click="search"><span v-html="$t('masterdata_search')" /></v-btn>
    <v-btn color="secondary" @click="reset"><span v-html="$t('masterdata_reset')" /></v-btn>
    <v-btn color="secondary" :to="{ name: 'applicationUsers_create' }"><span v-html="$t('application_users_create')" /></v-btn>

    <v-data-table-server
      :headers="fields"
      :items="items"
      :fields="fields"
      :loading="loading"
      :items-length="totalElements"
      :multi-sort="true"
      @update:itemsPerPage="routerPushSize"
      @update:page="routerPushPage"
      @update:sort-by="routerPushDTSort"
      :sort-by="toDTSort(query.sort)"
      :page="getDTPageFromRoute(query.page)"
      :items-per-page="query.size"
      :items-per-page-options="$itemsPerPageOptions"
      density="compact"
    >
      <template v-slot:[`item.allowedLegalEntities`]="{ item }">
        <table class="customTable">
          <tr v-for="legalEntity in item.allowedLegalEntities" :key="legalEntity.id">
            <td>{{ legalEntity.name1 }}</td>

            <td>{{ legalEntity.name2 }}</td>

            <td>{{ $formatChDateTime(legalEntity.validUntil) }}</td>
          </tr>
        </table>
      </template>
      <template v-slot:[`item.name`]="{ item }">
        {{ item.name }}
        <template v-if="item.allowedLegalEntities.length">
          <span class="link" v-if="$privileges.has({ path: '/userSwitch', permission: 'read' })" @click="setSelectedApplicationUser(item)"
            ><v-icon right>mdi-chevron-right</v-icon></span
          >
        </template>
      </template>
      <template v-slot:[`item.edit`]="{ item }">
        <template v-if="item.id">
          <router-link :to="{ name: 'applicationUsers_edit', params: { id: item.id } }">
            <v-icon dbmblueprimary>mdi-text-box-edit-outline</v-icon>
          </router-link>
        </template>
      </template>
    </v-data-table-server>
  </v-container>
</template>
<script lang="ts">
import { apiURL } from '@/main'
import { Term } from '@/services/term'
import searchLegalEntity from '@/components/searchCards/searchLegalEntity.vue'
import { useSearchLegalEntityStore } from '@/store/SearchLegalEntityStore'
import searchContact from '@/components/searchCards/searchContact.vue'
import { useSearchContactStore } from '@/store/SearchContactStore'

import _ from 'lodash'
import { useSearchApplicationUserStore } from '@/store/SearchApplicationUserStore'
let searchApplicationUserStore = useSearchApplicationUserStore()
import { showError } from '@/services/axios'
import SearchApplicationUser from '@/components/searchCards/searchApplicationUser.vue'
import { personsService } from '@/services/persons'
import { DTSHeader } from '@/services/BackendService'

//http://localhost:8199/api/masterdata/search?term=applicationUser_applicationRoleId%3A1&domainId=1&roleId=1
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'form_rows_read',
  data() {
    return {
      dialog: false,
      items: [],
      loading: false,
      initialized: false,

      totalElements: 0,
      fields: <DTSHeader[]>[
        // edit
        {
          title: this.$t('applicationUsers_edit'),
          key: 'edit',
          sortable: false,
          width: '2%'
        },
        // Name
        {
          title: this.$t('applicationUsers_name'),
          key: 'name',
          sortable: true,
          align: 'left'
        },
        // Email
        {
          title: this.$t('applicationUsers_email'),
          key: 'email',
          sortable: true,
          align: 'left'
        },
        // Agate ID
        {
          title: this.$t('applicationUsers_agateId'),
          key: 'agateId',
          sortable: true,
          align: 'left'
        },
        // Legal Entity
        {
          title: this.$t('applicationUsers_legalEntities'),
          key: 'allowedLegalEntities',
          sortable: false,
          align: 'left'
        }
      ]
    }
  },
  computed: {
    term(): any {
      return [
        ...Term.buildTermItems(useSearchLegalEntityStore(), useSearchLegalEntityStore().items),
        ...Term.buildTermItems(useSearchContactStore(), useSearchContactStore().items),
        ...Term.buildTermItems(searchApplicationUserStore, searchApplicationUserStore.items)
      ]
    },
    query(): any {
      return {
        term: Term.stringify(this.term),
        ...this.getJavaPageOptions({ sort: ['name,asc'] })
      }
    }
  },
  components: {
    searchLegalEntity,
    searchContact,
    SearchApplicationUser
  },
  methods: {
    async setSelectedApplicationUser(applicationUser) {
      await personsService.impersonateAsUser(applicationUser)
    },

    goToApplicationUsersEdit(userId) {
      this.$router.push({ name: 'applicationUsers_edit', params: { id: userId } }).catch(() => {
        /* duplicated route */
      })
    },
    search() {
      const term = Term.stringify(this.term)
      this.$router
        .push({
          path: this.$route.path,
          query: this.query
        })
        .catch((e) => {
          // route duplicated ..
          this.load()
        })
    },
    async load() {
      this.loading = true
      try {
        const response = await this.axios.get(apiURL + '/applicationUsers/find', {
          params: this.query
        })
        const items = await response.data
        this.totalElements = items.totalElements
        this.items = Array.isArray(items.content) ? items.content : []
      } catch (e) {
        showError(e)
      } finally {
        this.loading = false
      }
    },
    reset() {
      this.$refs['search-legal-entity'].reset()
      this.$refs['search-contact'].reset()
      this.$refs['search-application-user'].reset()
    },
    onClickCheckboxSum(value) {
      this.sumCheckbox = value
    }
  },
  watch: {
    /* if someone changes the route by hand */
    '$route.query': {
      handler(newValue, oldValue) {
        this.load()
      },
      deep: true
    }
  },
  mounted() {
    this.initialized = true
    if (this.$route.query.size) this.load()
  }
})
</script>
